import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import PreviewSectionTitle from './SectionTitle'

// images 
import home from '../images/demo/home.jpg';
import home2 from '../images/demo/home2.jpg';
import home3 from '../images/demo/home3.jpg';
import about from '../images/demo/about-us.jpg';
import blog from '../images/demo/blog.jpg';
import contact from '../images/demo/contact-us.jpg';
import career from '../images/demo/career.jpg';
import casestudy from '../images/demo/case-study.jpg';
import faq from '../images/demo/faq.jpg';
import goal from '../images/demo/goal.jpg';
import quotes from '../images/demo/quotes.jpg';
import singleblog from '../images/demo/single-blog.jpg';
import singlecasestudy from '../images/demo/single-case-study.jpg';
import Error from '../images/demo/404.jpg';

const demos = [
    {name: 'Home Page', image: home, link: 'home'},
    {name: 'Home Two Page', image: home2, link: 'home2'},
    {name: 'Home Three Page', image: home3, link: 'home3'},
    {name: 'About Us', image: about, link: 'about-us'},
    {name: 'Blog Page', image: blog, link: 'blog'},
    {name: 'Contact Us', image: contact, link: 'contact-us'},
    {name: 'Career', image: career, link: 'career'},
    {name: 'Case Study', image: casestudy, link: 'case-study'},
    {name: 'Single Case Study', image: singlecasestudy, link: 'single-case-study/1'},
    {name: 'Faq', image: faq, link: 'faq'},
    {name: 'Goal', image: goal, link: 'goal'},
    {name: 'Quotes', image: quotes, link: 'quotes'},
    {name: 'Single Blog', image: singleblog, link: 'single-blog/1'},
    {name: 'Error Page', image: Error, link: 'error-page'},
];

const PreviewDemo = () => {
    return (
        <Fragment>
            <div className="previewDemoArea" id="demo">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <PreviewSectionTitle
                                title="Demo"
                                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
                            />
                        </div>
                        {demos.map((demo, i) => (
                            <div key={i} className="col-sm-6 col-12">
                                <div className="demoWrapper">
                                    <Link className="demoInfo" target="_blank" to={`/${demo.link}`}>{demo.name}</Link>
                                    <div className="demoImg">
                                        {demo.new && <span> {demo.new} </span>}
                                        <img src={demo.image} alt=""/>
                                    </div>
                                    <div className="demoContent">
                                        <h3>{demo.name}</h3>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PreviewDemo